const layouts = () => import('@/views/layout')
export default [
  {
    path: '/device-manage',
    redirect: '/device-manage/device-list',
    component: layouts,
    meta: {
      title: '设备管理',
      isMenu: true
    },
    children: [
      {
        path: 'device-list',
        name: 'deviceList',
        component: () => import('@/views/device-manage/device-list'),
        meta: {
          title: '设备列表',
          isMenu: true
        }
      },
      {
        path: 'visual-template',
        name: 'visualTemplate',
        component: () => import('@/views/device-manage/visual-template'),
        meta: {
          title: '可视化模版',
          isMenu: true
        }
      },
      {
        path: 'visual-template/config',
        name: 'visualConfig',
        component: () => import('@/views/device-manage/visual-template/config'),
        meta: {
          title: '编辑',
          isMenu: false
        }
      }
    ]
  }
]
