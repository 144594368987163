<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}

</script>
<style lang="scss">
@import '@/style/reset.scss';
@import '@/style/app.scss';
</style>
