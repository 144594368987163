/*
 * @Author: 张贤元 wb_zhangxianyuan@tcl.com
 * @Date: 2022-07-11 10:12:49
 * @LastEditors: 张贤元 wb_zhangxianyuan@tcl.com
 * @LastEditTime: 2022-10-28 15:12:05
 * @FilePath: /i18n-saas-IoT-frontend/src/router/modules/operation-manage.js
 * @Description: 运维管理路由模块
 */
const layouts = () => import('@/views/layout')
export default [
  {
    path: '/operation-manage',
    redirect: '/operation-manage/operation-log',
    component: layouts,
    meta: {
      title: '运维管理',
      isMenu: true
    },
    children: [
      {
        path: 'operation-log',
        name: 'operationLog',
        component: () => import('@/views/operation-manage/operation-log'),
        meta: {
          title: '操作日志',
          isMenu: true
        }
      },
      {
        path: 'exception-log',
        name: 'exceptionLog',
        component: () => import('@/views/operation-manage/exception-log'),
        meta: {
          title: '异常日志',
          isMenu: true
        }
      },
      {
        path: 'version-upgrade',
        name: 'versionUpgrade',
        component: () => import('@/views/operation-manage/version-upgrade'),
        meta: {
          title: '柜机版本升级',
          isMenu: true
        }
      },
      {
        path: 'version-upgrade/new-cabinet',
        name: 'newCabinet',
        beforeEnter: (to, from, next) => {
          if (to.query.id) {
            to.meta.title = '查看'
          } else {
            to.meta.title = '新增柜机升级'
          }
          next()
        },
        component: () => import('@/views/operation-manage/version-upgrade/new-cabinet'),
        meta: {
          isMenu: false
        }
      },
      {
        path: 'mobile-upgrade',
        name: 'mobileUpgrade',
        component: () => import('@/views/operation-manage/mobile-upgrade'),
        meta: {
          title: '移动端升级',
          isMenu: true
        }
      },
      {
        path: 'version-management',
        name: 'versionManagement',
        component: () => import('@/views/operation-manage/version-management'),
        meta: {
          title: '版本管理',
          isMenu: true
        }
      }
    ]
  }
]
