/* eslint-disable */
import { dateFormatter } from '@/utils/index'

const getStorageKeys = name => {
  const namespace = '_LOT'
  return {
    dataKey: [namespace, name].join('_'),
    timeKey: [namespace, name, 'EXPIRE'].join('_')
  }
}

const tryAgain = (fn, ctx, ...args) => {
  try {
    return fn.apply(ctx, args)
  } catch (e) {
    try {
      return fn.apply(ctx, args)
    } catch (e) { }
  }
}

const clearStorage = storage => {
  tryAgain(storage.clear, storage)
}

const removeStorage = (storage, key) => {
  const removeItem = storage.removeItem
  const keys = getStorageKeys(key)
  tryAgain(removeItem, storage, keys.dataKey)
  tryAgain(removeItem, storage, keys.timeKey)
}

const getStorage = (storage, key) => {
  const getItem = storage.getItem
  const keys = getStorageKeys(key)
  const expire = parseInt(tryAgain(getItem, storage, keys.timeKey), 10)
  if (Number.isNaN(expire) || Date.now() < expire) {
    return tryAgain(getItem, storage, keys.dataKey) || null
  }
  removeStorage(storage, key)
  return null
}

const setStorage = (storage, key, value, expire) => {
  const setItem = storage.setItem
  const keys = getStorageKeys(key)
  const time = parseInt(dateFormatter(expire, 'ts'), 10)
  if (Number.isNaN(time)) {
    return tryAgain(setItem, storage, keys.dataKey, value)
  }
  if (Date.now() > time) {
    return removeStorage(storage, key)
  }
  tryAgain(setItem, storage, keys.dataKey, value)
  tryAgain(setItem, storage, keys.timeKey, time + '')
}

/**
 * 清空所有localStorage
 */
export const clearLocalStorage = () => {
  return clearStorage(window.localStorage)
}

/**
 * 删除某项localStorage
 * @param {string} key
 */
export const removeLocalStorage = key => {
  return removeStorage(window.localStorage, key)
}

/**
 * 获取某项localStorage
 * @param {string} key
 * @return {string|null}
 */
export const getLocalStorage = key => {
  return getStorage(window.localStorage, key)
}

/**
 * 设置localStorage
 * @param {string} key       - 缓存名字
 * @param {string} value     - 缓存数据
 * @param {number} [expire]  - 过期时间戳
 */
export const setLocalStorage = (key, value, expire) => {
  return setStorage(window.localStorage, key, value, expire)
}

/**
 * 获取某个sessionStorage
 * @param {string} key
 * @return {string|null}
 */
export const getSessionStorage = key => {
  return getStorage(window.sessionStorage, key)
}

/**
 * 删除某个sessionStorage
 * @param {string} key
 */
export const removeSessionStorage = key => {
  return removeStorage(window.sessionStorage, key)
}

/**
 * 设置sessionStorage
 * @param {string} key       - 缓存名字
 * @param {string} value     - 缓存数据
 * @param {number} [expire]  - 过期时间戳
 */
export const setSessionStorage = (key, value, expire) => {
  return setStorage(window.sessionStorage, key, value, expire)
}

/**
 * 清空所有sessionStorage
 */
export const clearSessionStorage = () => {
  return clearStorage(window.sessionStorage)
}
