/**
 * 注册全局的Element组件
 */
import 'element-ui/lib/theme-chalk/index.css'
import Vue from 'vue'
import {
  Pagination,
  Dialog,
  Divider,
  // Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  // MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  Message,
  MessageBox,
  Notification,
  Loading,
  Aside,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Col,
  TimePicker,
  Row,
  Popover,
  Autocomplete,
  Empty,
  Link,
  Scrollbar,
  Tooltip,
  Image
} from 'element-ui'

const components = {
  Button,
  Pagination,
  Dialog,
  Divider,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  // MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  ButtonGroup,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  Aside,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Col,
  TimePicker,
  Row,
  Popover,
  Empty,
  Link,
  Scrollbar,
  Tooltip,
  Image
}
Object.entries(components).forEach(([key, component]) => {
  Vue.use(component)
})
Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message
