import Vue from 'vue'
import VueRouter from 'vue-router'
// import Layout from '@/views/layout'
import staticRoutes from './staticRouter'
import store from '@/store'
import {
  getLocalStorage
} from '@/utils/storage.js'
// import { isObject } from '@/utils'
Vue.use(VueRouter)

// 解决冗余导航报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const getLayoutChildRoutes = () => {
  const ctx = require.context('@/router/modules', false, /\.js$/)
  const modules = ctx.keys().map(key => ctx(key).default)
  return Array.prototype.concat.apply([], modules)
}
const routes = getLayoutChildRoutes()

const createRouter = () => new VueRouter({
  mode: 'hash',
  // mode: 'history',
  routes: staticRoutes.concat(routes)
})
const router = createRouter()

// 获取路由名称
const getPageTitle = route => {
  const defaultTitle = 'IoT管理平台'
  const meta = route.meta
  if (meta && typeof meta.title === 'string') {
    return `${meta.title} | ${defaultTitle}`
  }
  return defaultTitle
}

// 判断是否为静态路由
const isStaticRoute = routeName => {
  return staticRoutes.some(item => item.name === routeName)
}

// 处理路由权限
router.beforeEach((to, from, next) => {
  // console.log('to: ', to, routes)
  // const toRouteMeta = isObject(to.meta) ? to.meta : {}
  // console.log('toRouteMeta: ', toRouteMeta)
  const userId = store.getters.userInformation.userId
  if (isStaticRoute(to.name)) {
    return next()
  }
  if (to.name !== 'Login' && !userId) {
    return next({ name: 'Login' })
  }
  if (!to.matched.length) {
    return next({ name: 'NotFound' })
  }
  return next()
})

router.afterEach((to) => {
  document.title = getPageTitle(to)
  // 存储用户信息
  if (!store.getters.userInformation.token) {
    const data = JSON.parse(getLocalStorage('userInformation'))
    store.commit('setUserInformation', data)
  }
})

export default router
