import { axios1 } from '../instance'
import store from '@/store'

// 登录

/**
 * 获取验证码
 * @return {Promise}
 */
export const getCaptcha = () => {
  return axios1.get('/auth/captcha/get', {
    headers: { 'Content-Type': 'application/json;charset=utf-8' },
    // cancelToken: new CancelToken(fn => {
    //   getCaptcha.cancel = fn
    // })
  })
}

/**
 * 获取公钥
 * @return {Promise}
 */
export const getPublicKey = () => {
  return axios1.get('/rsa/public')
}

/**
 * 用户登录
 * @param {object} data
 * @param {string} data.account 账号
 * @param {string} data.password 密码
 * @param {string} data.platformId 平台id （lot:001）
 * @return {Promise}
 */
export const loginIn = data => {
  console.log('loginIn-contentType', store.state.contentType)
  const config = {
    headers: { 'captcha-code': store.state.contentType }
  }
  return axios1.postByJson('/user/auth/login', data, config)
}

/**
 * 获取token
 * @param {object} data
 * @param {string} data.password 密码  RSA 加密
 * @param {string} data.grantType 平台id 'password'
 * @param {string} data.username  用户名， 不同平台，分配不同值 'ib-user'
 * @return {Promise}
 */
export const getToken = data => {
  return axios1.postByJson('/i18n-saas-oauth2-server/oauth/token', data)
}

/**
 * 用户退出
 * @return {Promise}
 */
export const logout = () => {
  return axios1.postByJson('/user/auth/logout')
}

/**
 * 首次登录修改密码
 * @param {object} data
 * @param {string} data.newPassword  新密码
 * @return {Promise}
 */
export const firstPassword = (data) => {
  return axios1.postByJson('/user/first/password', data)
}
