import axios from 'axios'
import { isObject, json2qs, format, getTimeZoneOffset } from '@/utils'
import { getLocalStorage, clearLocalStorage } from '@/utils/storage'
// import router from '@/router'
import Vue from 'vue'
import store from '@/store'
import router from '@/router'

// Content-Type: 'application/x-www-form-urlencoded;charset=utf-8'
// Content-Type: 'application/json;charset=utf-8'
// Content-Type: 'multipart/form-data'

const defaultOptions = {
  timeout: 0,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
  },
  validateStatus: status => {
    return status === 200
  },
  transformRequest: [(data, headers) => {
    const contentType = headers['Content-Type'] || headers['content-type']
    if (contentType.indexOf('application/x-www-form-urlencoded') !== -1) {
      return json2qs(data)
    }
    if (contentType.indexOf('application/json') !== -1) {
      return JSON.stringify(data)
    }
    return data
  }]
}

const mergeOptions = options => {
  if (!isObject(options)) {
    return defaultOptions
  }
  const config = Object.assign({}, defaultOptions)
  for (const key in options) {
    const origin = config[key]
    const val = options[key]
    config[key] = isObject(val) ? Object.assign({}, origin, val) : val
  }
  return config
}

/**
 * 增加一个postByJson的方法
 * @param instance
 * @return {function(string, [Object], [Object]): Promise}
 */
const createPostByJsonMethod = instance => {
  return (url, data, config) => {
    const headers = config ? Object.assign({}, config.headers) : {}
    headers['Content-Type'] = 'application/json;charset=utf-8'
    return instance.post(url, data, Object.assign(config || {}, { headers }))
  }
}
/**
 * 增加一个 postByFormData 的方法
 * @param instance
 * @return {function(string, [Object], [Object]): Promise}
 */
const createPostByFormData = instance => {
  return function(url, data, config) {
    const form = new FormData()
    format(data, form)
    const headers = config ? Object.assign({}, config.headers) : {}
    headers['Content-Type'] = 'multipart/form-data;charset=utf-8'
    return instance.post(url, form, Object.assign(config || {}, { headers }))
  }
}

// 创建axios实例
const baseURL = process.env.NODE_ENV === 'development' ? '/development' : ''
const axios1 = axios.create(mergeOptions({
  baseURL: baseURL
}))
axios1.postByJson = createPostByJsonMethod(axios1)
axios1.postByFormData = createPostByFormData(axios1)

// request拦截器
axios1.interceptors.request.use(config => {
  const authToken = store.state.authToken || getLocalStorage('Auth-Token')
  if (authToken) {
    config.headers['auth-token'] = authToken
    config.headers['X-Time-Zone'] = `UTC${getTimeZoneOffset()}`
  }
  return config
}, error => Promise.reject(error))

// respone拦截器
axios1.interceptors.response.use(response => {
  const { status, data, headers } = response
  // console.log('interceptors-data: ', data)
  if (status === 200 && isObject(data)) {
    if (data.code === '103000005') {
      router.replace({ name: 'Login' })
      clearLocalStorage()
    }
  }
  if (headers['captcha-code']) {
    const contentType = headers['captcha-code']
    store.commit('setContentType', contentType)
  }
  return response.data
}, error => {
  console.log('response-error: ', error)
  Vue.prototype.$message({
    type: 'warning',
    duration: 2000,
    message: error
  })
  return Promise.reject((error))
})

const CancelToken = axios.CancelToken
export {
  CancelToken,
  axios1
}
