const layouts = () => import('@/views/layout')
export default [
  {
    path: '/cabinet-manage',
    redirect: '/cabinet-manage/cabinet-list',
    component: layouts,
    meta: {
      title: '柜机管理',
      isMenu: true
    },
    children: [
      {
        path: 'cabinet-list',
        name: 'cabinetList',
        component: () => import('@/views/cabinet-manage/cabinet-list'),
        meta: {
          title: '柜机列表',
          isMenu: true
        }
      },
      {
        path: 'cabinet-list/details',
        name: 'cabinetListDetails',
        component: () => import('@/views/cabinet-manage/cabinet-list/details'),
        meta: {
          title: '柜机详情',
          isMenu: false
        }
      },
      {
        path: 'cabinet-list/creates',
        name: 'cabinetListCreates',
        beforeEnter: (to, from, next) => {
          if (to.query.id) {
            to.meta.title = '编辑柜机'
          } else {
            to.meta.title = '新增柜机'
          }
          next()
        },
        component: () => import('@/views/cabinet-manage/cabinet-list/creates'),
        meta: {
          isMenu: false
        }
      },
      {
        path: 'product-type',
        name: 'productType',
        component: () => import('@/views/cabinet-manage/product-type'),
        meta: {
          title: '产品类型',
          isMenu: true
        }
      }
    ]
  }
]
