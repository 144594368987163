import Vue from 'vue'
import Vuex from 'vuex'
import {
  loginIn,
  getPublicKey
} from '@/api/modules/login'
import { isObject } from '@/utils'
import { setLocalStorage, getLocalStorage } from '@/utils/storage.js'
Vue.use(Vuex)

const createEmptyUser = () => {
  return {
    currentRoleId: '', // 角色 id
    currentRoleName: '', // 角色名称
    areaNo: '', // 手机号区码
    phone: '', // 手机号
    firstLogin: '', // 是否首次登录(1-是，2-不是)
    iconAddress: '', // 头像链接
    userName: '', // 用户名
    userId: '', // 用户id
    email: '', // 邮箱
    token: '', // 登录token
    uicId: '', // 全局用户id
    userType: '', // 1-内部用户，2-外部用户
    registerPlatformId: '', // 注册平台
  }
}

const rootModule = {
  state: {
    authToken: '',
    contentType: '',
    userInformation: createEmptyUser(),
    publicKey: '' || getLocalStorage('publicKey')
  },
  getters: {
    authToken(state) {
      return state.authToken
    },
    userInformation(state) {
      const pre = JSON.parse(getLocalStorage('userInformation'))
      if (!state.userInformation.userId && !pre) {
        return state.userInformation
      }
      if (!state.userInformation.userId) {
        return pre
      }
      return state.userInformation
    }
  },
  mutations: {
    setToken(state, authToken) {
      state.authToken = typeof authToken === 'string' ? authToken : ''
    },
    setContentType(state, contentType) {
      state.contentType = contentType
    },
    setUserInformation(state, user) {
      state.userInformation = isObject(user) ? user : createEmptyUser()
    },
    setPublicKey(state, publicKey) {
      state.publicKey = publicKey
    }
  },
  actions: {
    login(store, data) {
      return loginIn(data).then(res => {
        console.log('loginIn-res: ', res)
        if (res.code === '000000000') {
          setLocalStorage('Auth-Token', res.data.token || '')
          setLocalStorage('userInformation', JSON.stringify(res.data))
          store.commit('setToken', res.data.token)
          store.commit('setUserInformation', res.data)
        }
        return res
      })
    },
    getPublicKey(store) {
      return getPublicKey().then(res => {
        if (res.code === '000000000') {
          store.commit('setPublicKey', res.data.publicKey)
          setLocalStorage('publicKey', res.data.publicKey)
        }
        return res
      })
    }
  },
  modules: {}
}

const files = require.context('./modules', false, /\.js$/)
files.keys().forEach(key => {
  const store = files(key).default
  const moduleName = key.replace(/^\.\//, '').replace(/\.js$/, '')
  const modules = rootModule.modules = (rootModule.modules || {})
  modules[moduleName] = store
  rootModule.modules = modules
})

const store = new Vuex.Store(rootModule)
export default store
