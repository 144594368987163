const layouts = () => import('@/views/layout')
export default [
  {
    path: '/',
    name: 'Dashboard',
    redirect: '/dashboard',
    component: layouts,
    children: [
      {
        path: '/dashboard',
        component: () => import('@/views/dashboard'),
        meta: {
          menuId: '',
          title: '控制台',
          icon: ''
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login'),
    meta: {
      menuId: '',
      title: '登录',
      icon: ''
    }
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('@/views/error'),
    meta: {
      menuId: '',
      title: '出错了',
      icon: '',
    }
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import('@/views/error/not-found'),
    meta: {
      menuId: '',
      title: '页面不存在',
      icon: '',
    }
  }
]
