const layouts = () => import('@/views/layout')
export default [
  {
    path: '/system-manage',
    redirect: '/system-manage/user-manage',
    component: layouts,
    meta: {
      title: '系统管理',
      isMenu: true
    },
    children: [
      {
        path: 'user-manage',
        name: 'userManage',
        component: () => import('@/views/system-manage/user-manage'),
        meta: {
          title: '用户管理',
          isMenu: true
        }
      }
    ]
  }
]
